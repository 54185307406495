import { storyblokEditable } from '@storyblok/react/rsc'
import { type FC } from 'react'

import { Button } from 'base-ui'

import { useGlobalConfigContext } from '../../../global-config'
import { urlResolverGetSearchPage } from '../../../url-handling'
import { type StoryblokComponentProps } from '../../model'
import { type StoryblokMostSearchedKeywordsType } from '../../model/storyblokTypes.generated'
import { useStoryblokServerSideData } from '../../storyblok-server-side-data/context/storyblokServerSideDataContext'

export const StoryblokMostSearchedKeywords: FC<
  StoryblokComponentProps<StoryblokMostSearchedKeywordsType>
> = ({ blok }) => {
  const globalConfig = useGlobalConfigContext()
  const { data } = useStoryblokServerSideData(blok)

  return (
    <div
      {...storyblokEditable(blok)}
      className="flex flex-wrap justify-start gap-4"
    >
      {data?.map((keyword) => {
        return (
          <Button<'a'>
            color="secondary"
            href={urlResolverGetSearchPage(globalConfig, {
              // eslint-disable-next-line id-length -- q is a query parameter
              queryParams: { q: keyword },
            })}
            key={keyword}
            size="medium"
            slots={{ root: 'a' }}
            variant="filled"
          >
            {keyword}
          </Button>
        )
      })}
    </div>
  )
}
